
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "promoaction",
  });

  get caption(){
    return this.dataSource.model?.caption
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Промоакции",
        to: "/manage/promoactions/",
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }
}
